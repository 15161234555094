$mobile-max-width-breakpoint: 767px;
$mobile-min-width-breakpoint: 768px;
$content-max-width: 1350px;
$navbar-height: 70px;
$footer-height: 100px;

$global-animation-time: 0.4s;

$global-hyperlink-colour: #1c72d3;

/* ### Default (dark) theme. ### */
// Background
$dark-primary-background-colour: #202020;
$dark-secondary-background-colour: #292929;
//$dark-tertiary-background-colour: #ffffff;

// Font
$dark-primary-font-colour: #bdc2cc;
/*$dark-secondary-font-colour: #ffffff;
$dark-tertiary-font-colour: #ffffff;*/

// Borders
//$dark-primary-border-colour: rgba(128, 128, 128, 0.3);
//$dark-primary-border-colour-hover: rgba(128, 128, 128, 0.1);
//$dark-secondary-border-colour: rgba(128, 128, 128, 0.1);
//$dark-tertiary-border-colour: #ffffff;

/* ### Secondary (light) theme ### */
// Background
$light-primary-background-colour: #e3e3e3;
$light-secondary-background-colour: #ececec;
//$light-tertiary-background-colour: #ffffff;

// Font
$light-primary-font-colour: #454545;
//$light-secondary-font-colour: #1c6ef7;
//$light-tertiary-font-colour: #ffffff;

$standard-blue-background: #1c6ef7;
$dark-blue-background: #0b4dbb;

$standard-red-background: #cf4343;
$dark-red-background: #ab1d1d;

/* ### General variables ### */
// Navbar
$dark-navbar-background-colour: #131313;
$dark-navbar-font-colour: #c1c6d0;
$dark-navbar-font-colour-hover: #ffffff;

$light-navbar-background-colour: $light-secondary-background-colour;
$light-navbar-font-colour: #454545;
$light-navbar-font-colour-hover: #1c6ef7;

$box-shadow-color: 0.5;

$light-border: 1px solid rgba(0, 0, 0, 0.2);
$dark-border: 1px solid rgba(204, 204, 204, 0.1);
