@import '../shared/variables.scss';

$mobile-breakpoint: 1150px;

.skillarea-summary-tiles {
    // Box shadow applied to parent so the shadow has no gaps when items are next to each other.
    box-shadow: 4px 4px 12px -4px rgba(0, 0, 0, $box-shadow-color);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-radius: 5px;
    background-color: transparent;
    margin-top: 20px;

    @media (max-width: $mobile-breakpoint) {
        max-width: 600px;
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        margin: 0 auto;
    }

    .skillarea-summary-tile {
        border-radius: 0px;
        padding: 25px 20px;
        width: 100%;

        @media (max-width: $mobile-breakpoint) {
            max-width: 600px;
            padding: 20px 0px;
        }

        .skillarea-summary-img-container {
            width: 100%;
            text-align: center;

            @media (max-width: 698px) {
                max-width: 100%;
            }

            i {
                font-size: 50px;
                color: #1c6ef7;
            }
        }

        .skillarea-summary-tile-content {
            padding: 15px 15px;
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        .skillarea-summary-tile-title {
            font-weight: bold;
            text-align: center;
            font-size: 24px;
            margin: 10px 0px 5px;
        }
        //https://stackoverflow.com/a/53156390/5547950
        $font-size: 14px;
        $line-height: 1.4;
        $lines-to-show: 5;

        .skillarea-summary-tile-desc {
            margin-top: 15px;
            margin-bottom: 20px;
        }

        .skillarea-summary-tile-other {
            display: flex;
            flex-direction: row;
            max-height: 152px;
            overflow: auto;

            ul.skillarea-summary-tile-techs {
                list-style-position: inside;
                padding: 0px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;

                > li {
                    margin-right: 8px;
                    font-size: 14px;
                    margin-bottom: 5px;
                    white-space: nowrap;
                    max-width: calc(50% - 8px);
                    width: calc(50% - 8px);

                    > span {
                        font-size: inherit;
                        margin-left: -5px;
                    }
                }

                .skillarea-summary-logo-container {
                    max-width: 30px;
                    max-height: 30px;
                    margin-right: 15px;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 5px;

                    @media (max-width: 520px) {
                        max-width: 25px;
                        max-height: 25px;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .skillarea-summary-logo {
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }

            .skillarea-summary-tile-links {
                display: flex;
                flex: 1;
                align-items: flex-end;
                justify-content: flex-end;

                > a {
                    margin-left: 10px;

                    > i {
                        font-size: 22px;
                    }
                }
            }
        }
    }

    .skillarea-summary-tile:first-child {
        @media (min-width: ($mobile-breakpoint + 1)) {
            border-right: none !important;
            border-radius: 5px 0px 0px 5px;
        }

        @media (max-width: $mobile-breakpoint) {
            border-bottom: none !important;
            border-radius: 5px 5px 0px 0px;
        }
    }

    .skillarea-summary-tile:last-child {
        @media (min-width: ($mobile-breakpoint + 1)) {
            border-left: none !important;
            border-radius: 0px 5px 5px 0px;
        }

        @media (max-width: $mobile-breakpoint) {
            border-top: none !important;
            border-radius: 0px 0px 5px 5px;
        }
    }
}

.skillarea-summary {
    display: flex;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;

    .proj-content-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        > div {
            display: flex;
            flex-direction: column;
        }

        .skillarea-links {
            flex-direction: row;

            > a:first-of-type {
                margin-right: 10px;
            }
        }

        .skillarea-image {
            max-width: 200px;
            max-height: 200px;
            width: 200px;
            height: 200px;
            border: 1px solid black;
        }

        ul {
            list-style: none;
            display: flex;
            flex-direction: row;
        }
    }

    .skillarea-desc {
        margin-top: 35px;
        margin-bottom: 35px;
    }

    .skillarea-subtitle {
        font-weight: bold;
        margin-right: 5px;
        margin-bottom: 0px;
    }

    .skillarea-title-section {
        height: 150px;

        h1 {
            margin: 0px;
            margin-right: 5px;
        }
    }
}
