@import '../shared/variables.scss';

.socials {
    display: flex;
    justify-content: space-around;
    flex: 1 0 30%;
    flex-direction: row;
    align-items: center;

    @media (max-width: 800px) {
        flex-direction: row;
        padding-top: 20px;
        padding-bottom: 20px;
        flex: 1 0 auto; //IE fix
    }

    > a {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $global-hyperlink-colour;
        margin: 10px;
        max-width: 60px;

        > i {
            font-size: 21px;
        }

        > div {
            @media (max-width: 450px) {
                display: none;
            }
        }
    }
}

.about {
    .socials {
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 60px;
        flex-wrap: wrap;

        > a {
            > div {
                background: $dark-secondary-background-colour;
                padding: 20px;
                border-radius: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 4px 4px 12px -4px rgba(0, 0, 0, $box-shadow-color);
                border: $dark-border;

                > i {
                    font-size: 28px;
                }
            }
        }
    }
}

.contact-form {
    flex: 1 0 70%;

    @media (max-width: 800px) {
        flex: 1 0 auto; //IE fix
    }

    textarea {
        height: 100px;
        max-width: 100%;
    }

    .contact-button {
        width: 150px;
        height: 40px;
        margin-top: 6px;
    }

    .contact-form-button-container {
        display: flex;
        justify-content: center;
        padding-top: 15px;

        @media (max-width: 768px) {
            display: flex;
            flex-direction: row;
        }

        .submit-button {
            margin-right: 40px;

            @media (max-width: 768px) {
                margin-right: 10px;
            }
        }
    }

    .form-error-message {
        opacity: 0;
        min-height: 24px;
        margin-top: 8px;
        color: #fb5353;
    }
}

.contact-form-info-container {
    .contact-form-info {
        display: flex;
        flex-direction: column;
        max-width: 825px;
        margin: 0 auto;
    }
}

.socials-form-info {
    display: flex;
    flex-direction: row;
}

.form-control::placeholder {
    color: #6c757d !important;
}

.form-control.is-valid {
    border-color: #198754 !important;
}

.form-control.is-invalid {
    border-color: #dc3545 !important;
}

.form-input-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > .form-group {
        width: 49%;

        @media (max-width: 600px) {
            width: 100%;
        }
    }

    @media (max-width: 600px) {
        flex-direction: column;
    }
}
