@import '../shared/variables.scss';

//Sticky footer solution:
//https://github.com/philipwalton/solved-by-flexbox/blob/master/assets/css/components/site.css
footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    margin-top: 0px;
    text-align: center;
    padding: 40px 0px 30px 0px;

    > div {
        max-width: 1000px;
        width: 100vw;
        padding: 0px 20px;
    }

    ul.footer-navlinks {
        list-style: none;
        padding: 0px;
        display: flex;
        justify-content: center;
        font-weight: bold;
        flex-wrap: wrap;
        display: none; // Not necessary as navbar is always viewable.

        > li {
            padding: 0px 10px;

            @media (max-width: 768px) {
                padding: 0px 5px;
            }

            > a {
                font-size: 21px;
                text-decoration: underline;
            }
        }
    }

    .socials-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        margin-top: 30px;
        flex-wrap: wrap;

        > a {
            margin: 0 10px;
            text-decoration: none;

            > div {
                background-color: #292929;
                border-radius: 15px;
                width: 45px;
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: center;

                > i {
                    font-size: 20px;
                }
            }
        }

        > a:hover,
        a:active {
            text-decoration: underline;
        }
    }

    .footer-top-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media (max-width: 600px) {
            flex-direction: column;
            align-items: center;
        }

        > div {
            max-width: 50%;

            @media (max-width: 600px) {
                max-width: 280px;
            }
        }

        > div:nth-child(1) {
            text-align: left;
            padding-right: 10px;

            @media (max-width: 600px) {
                text-align: center;
            }
        }

        > div:nth-child(2) {
            padding-left: 10px;
        }
    }

    .footer-bottom-content {
        margin-top: 50px;

        @media (max-width: 600px) {
            margin-top: 20px;
        }
    }

    .copyright-text {
        font-size: 14px;
    }

    h3 {
        font-weight: bold;
        font-size: 28px;
    }
}
