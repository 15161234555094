@import '../shared/variables.scss';

#landing-header {
    text-decoration: none;
    font-size: 55px;
    font-weight: bold;
    margin-bottom: 40px;
    border: none;
    padding-bottom: 0px;

    @media (max-width: 768px) {
        font-size: 36px;
    }
}

.section-title-wrapper-landing {
    text-align: center;

    h2 {
        font-weight: bold;
        text-align: center;
        padding-bottom: 10px;
        margin-bottom: 20px;
        display: inline-block;

        @media (max-width: 450px) {
            font-size: 30px;
            padding-bottom: 5px;
            margin: 10px 0px;
        }
    }
}

.projects-btn {
    text-transform: uppercase;
    width: 250px;
    height: 50px;
    font-weight: bold;
    letter-spacing: 1px;
}

.projects-btn-container {
    text-align: center;
    margin-top: 56px;
}

.landing {
    p {
        font-size: 18px;
    }
}
