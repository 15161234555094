@import '../shared/variables.scss';

$summary-skill-size: 150px;
$summary-skill-size-img: 100px;

.skill-summary-tiles {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    .skill-summary-tile {
        width: $summary-skill-size;
        height: $summary-skill-size;
        min-width: $summary-skill-size;
        min-height: $summary-skill-size;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 25px;
        margin-right: 30px;
        margin-bottom: 30px;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 4px 4px 12px -4px rgba(0, 0, 0, $box-shadow-color);

        img {
            display: block;
            max-width: $summary-skill-size-img;
            max-height: $summary-skill-size-img;
            width: auto;
            height: auto;
        }
    }
}
